import Step3Component from './Step3';
import { useApi } from '../AuthProvider';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Audiences from './modify_views/Audiences.js';
import Questions from './modify_views/Questions.js';

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { styled } from '@mui/material/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from './ui/Loading.js';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import { Step, StepLabel, Stepper } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';

const steps = ['Audience and Segments', 'Questions', 'Review and Run'];

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#202020',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const SurveyManager = ({ existingSurveyId, currentContext }) => {
  const [errorText, setErrorText] = useState('');
  const [loadedExistingSurvey, setLoadedExistingSurvey] = useState(false);
  const [persistedSurveyId, setPersistedSurveyId] = useState(null);
  const [surveyData, setSurveyData] = useState({});
  const api = useApi();
  const navigate = useNavigate();
  let surveyId = null;
  let surveyRunId = null;
  let audienceId = null;
  const [isSaving, setIsSaving] = useState(false);
  const [saveSurveySnackbarOpen, setSaveSurveySnackbarOpen] = useState(false);
  const addSegmentLimit = React.useState(20);
  const addQuestionsLimit = React.useState(50);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreatingSurvey, setIsCreatingSurvey] = useState(currentContext === 'create' ? true : false);
  const [isEditingSurvey, setIsEditingSurvey] = useState(currentContext === 'edit' ? true : false);
  const [isCopyingSurvey, setIsCopyingSurvey] = useState(currentContext === 'copy' ? true : false);

  // skip to any step that the URL tells you to
  // TODO make this redundant with routes and views, QAD version for now
  const display = searchParams.get('display');
  useEffect(() => {
    process.env.REACT_APP_ENV === 'staging' && console.log('searchParams:', searchParams);
    if (display === 'audiences') {
      setActiveStep(0);
    } else if (display === 'questions') {
      setActiveStep(1);
    } else if (display === 'preview') {
      setActiveStep(2);
    }
    process.env.REACT_APP_ENV === 'staging' && console.log('activeStep:', activeStep);
  }, [searchParams]);

  //step 0 related changes
  const [surveyName, setSurveyName] = useState('');
  const [hasSurveyNameError, setHasSurveyNameError] = useState(false);
  const [isShowAudienceError, setIsShowAudienceError] = useState(false);
  const [audienceData, setAudienceData] = useState([
    {
      id: 1,
      survey_id: "",
      audience: "",
      audience_id: ""
    }
  ]);
  const [existingAudiences, setExistingAudiences] = useState([]);// used to compare for edited state

  const validateSurveyName = (input) => {
    if (input.trim() === '') {
      setSurveyName(input);
      setHasSurveyNameError(true);
    } else {
      setHasSurveyNameError(false);
      setSurveyName(input);
    }
  }
  const hasNameData = surveyName.trim() !== '';
  const hasAudienceData = audienceData.some(textField => textField.audience.trim() !== '');
  const validateAudience = (input) => {
    process.env.REACT_APP_ENV === 'staging' && console.log('validating audience:', input);
    if (input.trim() === '' || input === '') {
      setAudienceData([{ ...audienceData[0], audience: input }]);
      setIsShowAudienceError(true);
    } else {
      setIsShowAudienceError(false);
      process.env.REACT_APP_ENV === 'staging' && console.log('about to set audience data:', audienceData);

      setAudienceData([{ ...audienceData[0], audience: input }]);

      process.env.REACT_APP_ENV === 'staging' && console.log('audience data after validate:', audienceData);
    }
  }
  const [existingSegments, setExistingSegments] = useState([]);// stores segments on load to detect add/remove later
  const [existingQuestions, setExistingQuestions] = useState([]);// stores questions on load to detect add/remove later
  const [segmentData, setSegmentData] = useState([]);
  const [newSegmentName, setNewSegmentName] = useState('');
  const [newSegmentSize, setNewSegmentSize] = useState('');
  const [hasSegmentNameError, setHasSegmentNameError] = useState(false);
  const [hasSegmentSizeError, setHasSegmentSizeError] = useState(false);
  const [hasSegmentCountError, setHasSegmentCountError] = useState(false);
  const hasSegmentData = segmentData.some(seg => seg.segment_name && seg.segment_name.trim() !== '' && seg.segment_size !== '');

  const addSegment = () => {
    const segmentNameIsValid = segmentData.length !== addSegmentLimit && newSegmentName.trim() !== '';
    const segmentSizeIsValid = segmentData.length !== addSegmentLimit && parseInt(newSegmentSize) >= 1 && parseInt(newSegmentSize) <= 1000;

    if (segmentNameIsValid && segmentSizeIsValid) {
      setSegmentData([...segmentData,
        { id: Math.floor(Math.random() * 10000),
          segment_name: newSegmentName,
          segment_size: parseInt(newSegmentSize),
          audience_id: audienceData[0].audience_id,
          audience_segment_id: '',
          sort_order: segmentData.length + 1,
          has_name_error: false,
          has_size_error: false
        }]);
      setNewSegmentName('');
      setNewSegmentSize('');
      setHasSegmentNameError(false);
      setHasSegmentSizeError(false);
      setHasSegmentCountError(false);
    } else {
      if (newSegmentName.trim() === '') {
        setHasSegmentNameError(true);
      }
      if (!newSegmentSize || newSegmentSize.trim() === '' || parseInt(newSegmentSize) <= 0 && parseInt(newSegmentSize) <= 1000) {
        setNewSegmentSize('');
        setHasSegmentSizeError(true);
      }
    }
  };

  // actions represent the buttons and their functions showing in the table's "Actions" col
  const segmentActions = [
    {
        label:'Delete',
        callback: async (params) => {
          process.env.REACT_APP_ENV === 'staging' && console.log('deleting segment:', params);
          process.env.REACT_APP_ENV === 'staging' && console.log(segmentData);
            const response = params.row.audience_segment_id !== '' ? await api.delete(`/audiences/segments/${params.row.audience_segment_id}`) : true;
            if (!response) {
                console.log('error deleting segment');
            } else {
                console.log('segment deleted');
            }
            // set segment data after deleting based on segment id
            // Update segment data with reordered segments
            setSegmentData(prevSegments => {
              // Filter out deleted segment
              const remainingSegments = prevSegments.filter(segment =>
                segment.id !== params.row.id
              );
              // Reorder remaining segments
              return remainingSegments.map((segment, index) => ({
                ...segment,
                sort_order: index + 1
              }));
            });
            // setSegmentData(segmentData.filter(segment => segment.id !== params.row.id));
            setExistingSegments(existingSegments.filter(segment => segment.id !== params.row.id));
        },
    }
  ];
  const validateSegment = (name, value) => {
    if (name === 'segment_name' && value.trim() === '') {
      setHasSegmentNameError(true);
    } else if (name === 'segment_size' && (value.trim() === '' || parseInt(value) <= 0 || parseInt(value) > 1000)) {
      setHasSegmentSizeError(true);
    } else {
      setHasSegmentNameError(false);
      setHasSegmentSizeError(false);
    }
    setSegmentData(segmentData);
  };
  //step 0 end

  //step 1 related changes
  const [hasQuestionTextError, setHasQuestionTextError] = useState(false);
  const [hasQuestionOptionsError, setHasQuestionOptionsError] = useState(false);
  const [hasQuestionCountError, setHasQuestionCountError] = useState(false);
  const [questionsData, setQuestionsData] = useState([]);
  const [newQuestionText, setNewQuestionText] = useState('');
  const [newQuestionType, setNewQuestionType] = useState('short response');
  const [newQuestionOptions, setNewQuestionOptions] = useState('');
  const [newQuestionRankings, setNewQuestionRankings] = useState(['', '', '', '', '']);

  const questionActions = [
    {
        label:'Delete',
        callback: async (params) => {
          process.env.REACT_APP_ENV === 'staging' && console.log('deleting question:', params.row);
          process.env.REACT_APP_ENV === 'staging' && console.log(questionsData);
          if (params.row.question_id !== '') {
            const response = await api.delete(`/questions/${params.row.question_id}`);
            if (response && response.status !== 200) {
                console.log('error deleting question');
            } else {
                process.env.REACT_APP_ENV === 'staging' && console.log('saved question has been deleted');
            }
          } else {
            process.env.REACT_APP_ENV === 'staging' && console.log('un-saved question has been deleted');
          }

          // preserve sort order after deletion
          setQuestionsData(prevQuestions => {
            // Filter out deleted question
            const remainingQuestions = prevQuestions.filter(question =>
              question.id !== params.row.id
            );
            // Reorder remaining questions
            return remainingQuestions.map((question, index) => ({
              ...question,
              sort_order: index + 1
            }));
          });

          // make sure the question doesn't remain in any state data
          //setQuestionsData(questionsData.filter(ques => ques.id !== params.row.id));
          setExistingQuestions(existingQuestions.filter(ques => ques.id !== params.row.id));
        },
    }
  ];

  const validateQuestion = () => {
    if (0 !== questionsData.length !== addQuestionsLimit && newQuestionText.trim() !== '') {
      setHasQuestionTextError(false);
      return true;
    } else {
      setHasQuestionTextError(true);
      return false;
    }
  }
  const validateOptions = () => {
    if (newQuestionType !== 'single choice' && newQuestionType !== 'multiple choice') {
      return true;
    } else {
      if (newQuestionOptions.trim() === '') {
        setHasQuestionOptionsError(true);
        return false;
      } else {
        setHasQuestionOptionsError(false);
        return true;
      }
    }
  }

  const hasQuestionsData = questionsData.some(textField => textField.question_text !== '' && textField.question_type !== '');
  const addQuestion = () => {

    if (questionsData.length !== addQuestionsLimit && validateQuestion() && validateOptions()) {
      if (process.env.REACT_APP_ENV === 'staging') {
        process.env.REACT_APP_ENV === 'staging' && console.log('adding question');
        process.env.REACT_APP_ENV === 'staging' && console.log('newQuestionText:', newQuestionText);
        process.env.REACT_APP_ENV === 'staging' && console.log('newQuestionOptions:', newQuestionOptions);
        process.env.REACT_APP_ENV === 'staging' && console.log('newQuestionType:', newQuestionType);
      }

      setQuestionsData(
        [...questionsData,
        {
          id: Math.floor(Math.random() * 10000),
          question_text: newQuestionText,
          question_type: newQuestionType,
          survey_id: isEditingSurvey ? surveyData.survey_id : '',
          sort_order: questionsData.length + 1,
          raw_options: newQuestionOptions,
          options: newQuestionOptions ? newQuestionOptions.split(',') : [],
          rankings: ['', '', '', '', ''],
          questions: '',
          question_id: "",
          has_question_error: false
        }
        ]);
      // reset inputs
      setNewQuestionText('');
      setNewQuestionType('short response');
      setNewQuestionOptions('');
      setHasQuestionCountError(false);
    }
  };

  const handleQuestionTypeFieldChange = (event) => {
    setNewQuestionType(event.target.value);
  };

  const handleRemoveValidationForQuestionsChange = (field, e) => {
    if (e.target.value.trim() !== '') {
      setHasQuestionTextError(false);
    }
    if (field === 'options' && e.target.value.trim() !== '') {
      setHasQuestionOptionsError(false);
    }
  };

  //step 1 end
  useEffect(() => {
    process.env.REACT_APP_ENV === 'staging' && console.log('name changed:', surveyName);
      setSurveyData({ ...surveyData, name: surveyName });
  }, [surveyName]);

  useEffect(() => {
      process.env.REACT_APP_ENV === 'staging' && console.log('audience changed:', audienceData);
      //validateAudience(audienceData[0].audience);
      setSurveyData({
        ...surveyData,
        survey_id: persistedSurveyId,
        audiences:[ ...audienceData ]
      });
  }, [audienceData]);

  useEffect(() => {
    if (segmentData.length > 0) {
      process.env.REACT_APP_ENV === 'staging' && console.log('segment changed:', segmentData);
      setSurveyData({ ...surveyData, audiences: [{
        ...surveyData.audiences[0],
        segments: segmentData
      }]});
    }
  }, [segmentData]);

  useEffect(() => {
    if (questionsData.length > 0) {
      process.env.REACT_APP_ENV === 'staging' && console.log('questions changed:', questionsData);
      setSurveyData({ ...surveyData, questions: questionsData });
    }
  }, [questionsData]);

  useEffect(() => {
    process.env.REACT_APP_ENV === 'staging' && console.log('surveyData changed:', surveyData);
    async function attemptSaveQuestions(surveyId) {
      const questionResponse = await saveQuestions(surveyId);

      if (!questionResponse && questionResponse !== 200) {
        console.log('error saving questions');
        setErrorText('Error saving questions');
      }
    }
    // check if we're actively saving
    if (isSaving) {
      if (surveyData.survey_id !== null && surveyData.survey_id !== '') {
        process.env.REACT_APP_ENV === 'staging' && console.log('survey has been created');
        // survey has been created
        if (surveyData.audiences[0].audience_id !== null && surveyData.audiences[0].audience_id !== '') {
          // audience has been created
          process.env.REACT_APP_ENV === 'staging' && console.log('saving and audience exists');
          // we only need to do this in step 0
          if (activeStep === 0) {
            attemptSaveQuestions(surveyData.survey_id);
          }
        }
      }
    }
  }, [surveyData]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };


  const handleCreateNext = async () => {
    let newSkipped = skipped;
    var isError = false;

    // start with no errors
    setErrorText('');

    // Fixed issue with calling createAudience() multiple times
    // by seperating coniditional check for activeStep === 0
    // and checking if audienceData is not empty
    if (activeStep === 0) {
      // audience check null or not
      if (!hasAudienceData || hasSurveyNameError || surveyName.trim() === '') {
        isError = true;
      }
      !hasAudienceData || isShowAudienceError ? setIsShowAudienceError(true) : setIsShowAudienceError(false);
      hasSurveyNameError || surveyName.trim() === '' ? setHasSurveyNameError(true) : setHasSurveyNameError(false);
    // segment check if null or not
      const segmentsWithBlankName = segmentData.some(seg => seg.segment_name.trim() === '');
      const segmentsWithBlankSize = segmentData.some(seg => seg.segment_size === '');

      if (segmentData.length === 0) {
        //setErrorText('Please add at least one segment to proceed');
        setHasSegmentCountError(true);
        return;
      } else if (segmentsWithBlankSize || segmentsWithBlankName) {
        setHasSegmentCountError(false);
        setSegmentData(segmentData.map(seg => {
          return {
            ...seg,
            has_name_error: seg.segment_name.trim() === '',
            has_size_error: seg.segment_size === ''
          }
        }));
        return;
      }
    }

    // question check if null or not
    if (activeStep === 1) {
      const questionsWithNoQuestion = questionsData.some(ques => ques.question_text.trim() === '');

      if (questionsData.length === 0) {
        // setErrorText('Please add at least one question to proceed');
        setHasQuestionCountError(true);
        return;
      }

      if (questionsWithNoQuestion) {
        setQuestionsData(questionsData.map(ques => {
          return {
            ...ques,
            has_question_error: ques.question_text.trim() === ''
          }
        }));
        return;
      }
    }

    // if nt error it will redirect to next page
    if (!isError) {
      if (activeStep === 0) {
        // name / audience / segments
        console.log(activeStep);
        saveSurvey();
        setActiveStep(activeStep + 1);
      } else if (activeStep === 1) {
        // questions
        saveSurvey();
        setActiveStep(activeStep + 1);
      } else if (activeStep === 2) {
        // review
        setIsSaving(false);
        setActiveStep(activeStep + 1);

        // create new survey mode, so create all the elements of the survey
        let stepSuccess = false;

        stepSuccess = await runSurvey();

        if (stepSuccess !== true) {
          setErrorText('Error running survey');
          setActiveStep(2);
          return;
        }

        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        }
    }
  };

  const selectSteps = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };



  const createSurvey = async (surveyName) => {
    if (persistedSurveyId !== null) {
      surveyId = persistedSurveyId;
      process.env.REACT_APP_ENV === 'staging' && console.log('persistedSurveyId:', surveyId);
    }

    if (surveyId !== null && surveyId !== '') {
      return surveyId;
    }

    try {
      const response = await api.post('/surveys/', {
        name: surveyName.substring(0, 255),
      });

      return response.data.survey_id;

    } catch (error) {
      console.error('Error making POST request', error);
      setErrorText(error.message || 'Error creating survey');
    }
  };

  const createAudience = async () => {
    if (isEditingSurvey && persistedSurveyId !== null) {
      surveyId = persistedSurveyId;
    }

    if (isCreatingSurvey || isCopyingSurvey) {
      process.env.REACT_APP_ENV === 'staging' && console.log('creating a new audience...');
      try {
        surveyId = await createSurvey(surveyName);
      } catch (error) {
        console.error('Error making POST request', error);
        setErrorText(error.message || 'Error creating survey');
        return false;
      } finally {
        setPersistedSurveyId(surveyId);
        setAudienceData([{ ...audienceData[0], survey_id: surveyId }]);
        // DEBUG: request and log the details of the newly created survey
        const surveyResponse = await api.get(`/surveys/${surveyId}`);
        process.env.REACT_APP_ENV === 'staging' && console.log('surveyResponse', surveyResponse);
        if (surveyResponse && surveyResponse.status === 200) {
          process.env.REACT_APP_ENV === 'staging' && console.log('survey created:', surveyResponse.data);
          try {
            const response = await api.post('/audiences/', {
              survey_id: surveyResponse.data.survey_id,
              audience: audienceData[0].audience,
            });
      
            if (response && response.status !== 200) {
              console.error('Error making POST request', response.data);
              setErrorText(response.data.message || 'Error creating audience');
              return false;
            } else {
              process.env.REACT_APP_ENV === 'staging' && console.log('posted to audiences/:', response.data);
              audienceId = response.data.audience_id;
              setAudienceData([{ ...audienceData[0], survey_id: response.data.survey_id, audience_id: response.data.audience_id }]);
              setExistingAudiences(audienceData);
              if (segmentData.length > 0) {
                const segmentSuccess = await saveSegments(response.data.audience_id);
                if (segmentSuccess !== true) {
                  setErrorText('Error creating segments');
                  setActiveStep(0);
                  return;
                } else {
                  console.log('segments created');
                }
              }
            }
          } catch (error) {
            console.error('Error making POST request', error);
            setErrorText(error || 'Error creating audience');
            return false;
          }
        }
      }
    }

    return true;
  };

  const saveSegments = async (audienceId) => {
    if (segmentData.length > 0) {
      // figure out which segments are new and which are existing by comparing
      // existingSegments with current segmentData
      process.env.REACT_APP_ENV === 'staging' && console.log('existingSegments:', existingSegments);
      process.env.REACT_APP_ENV === 'staging' && console.log('segmentData:', segmentData);
      segmentData.forEach(seg => {
        console.log('segment_id:', seg.id);
      });
      const newSegments = segmentData.filter(seg => seg.audience_segment_id === undefined || seg.audience_segment_id === '');
      process.env.REACT_APP_ENV === 'staging' && console.log('newSegments:', newSegments);
      // filter segmentData for segments that have the same ID as existingSegments but have been edited
      const editedSegments = segmentData.filter(seg => existingSegments.some(origSeg => origSeg.id === seg.id && (origSeg.segment_text !== seg.segment_text || origSeg.segment_size !== seg.segment_size || origSeg.sort_order !== seg.sort_order)));
      process.env.REACT_APP_ENV === 'staging' && console.log('editedSegments:', editedSegments);
      if (existingSegments.length === 0 && newSegments.length === 0 && editedSegments.length === 0) {
        console.log('all empty');
        console.log('segmentData:', segmentData);
      } else if (existingSegments.length === 0) {
        // there are no existing segments, so all segments in segmentData are new
        if (newSegments.length > 0) {
          try {
            // create new segments
            const segmentResponse = await api.post(`/audiences/segments/bulk`, {
              audience_id: audienceId,
              segments: newSegments
            });
            if (segmentResponse && segmentResponse.status === 200) {
              process.env.REACT_APP_ENV === 'staging' && console.log('new segments updated');
              // for every segment in segmentData, update the corresponding survey_id
              // with the one returned from the server
              segmentData.forEach((seg, index) => {
                seg.audience_segment_id = segmentResponse.data[index].audience_segment_id;
              });
            }
          } catch (error) {
            console.error('Error making POST request', error);
            setErrorText(error.message || 'Error creating new survey segments');
          }
        }
      } else {
        // there are existing segments, so we need to update the existing ones and create the new ones
        if (newSegments.length > 0) {
          try {
            // create new segments
            const segmentResponse = await api.post(`/audiences/segments/bulk`, {
              audience_id: audienceId,
              segments: newSegments
            });
            if (segmentResponse && segmentResponse.status === 200) {
              // for every segment in segmentData, update the corresponding segment_id and survey_id
              // with the one returned from the server
              segmentData.forEach((seg, index) => {
                if (!seg.audience_segment_id) {
                  // find the segment in segmentResponse with the same sort_order as the one in segmentData
                  // and set audience_segment_id to the new one returned from the server
                  for (let i = 0; i < segmentResponse.data.length; i++) {
                    if (seg.sort_order === segmentResponse.data[i].sort_order) {
                      seg.audience_segment_id = segmentResponse.data[i].audience_segment_id;
                    }
                  }
                }
              });
            }
          } catch (error) {
            console.error('Error making POST request', error);
            setErrorText(error.message || 'Error creating new survey segments');
          }
        }
        if (editedSegments.length > 0) {
          // update edited segments
          for (let i = 0; i < editedSegments.length; i++) {
            try {
              const segmentResponse = await api.put(`/audiences/segments/${editedSegments[i].audience_segment_id}`, {
                segment_name: editedSegments[i].segment_name,
                segment_size: parseInt(editedSegments[i].segment_size),
                sort_order: editedSegments[i].sort_order
              });
              if (segmentResponse && segmentResponse.status === 200) {
                console.log('edited segments updated');
              }
            } catch (error) {
              console.error('Error making POST request', error);
              setErrorText(error.message || 'Error editing survey segments');
            }
          }
        }
      }
      // if there are any edited segments, update the matching segment in segmentData
      // with the new segment data
      if (editedSegments.length > 0) {
        for (let i = 0; i < editedSegments.length; i++) {
          const index = segmentData.findIndex(seg => seg.id === editedSegments[i].id);
          segmentData[index] = editedSegments[i];
        }
      }
      // treat everything saved as existing when next save hits
      setSegmentData(segmentData);
      setExistingSegments(segmentData);
    }
    return true;
  }

  const saveQuestions = async (survey_id) => {
    if (questionsData.length > 0) {
      // figure out which questions are new and which are existing by comparing
      // existingQuestions with current questionsData
      process.env.REACT_APP_ENV === 'staging' && console.log('existingQuestions:', existingQuestions);
      process.env.REACT_APP_ENV === 'staging' && console.log('questionsData:', questionsData);
      const newQuestions = questionsData.filter(ques => ques.question_id === undefined || ques.question_id === '');
      process.env.REACT_APP_ENV === 'staging' && console.log('newQuestions:', newQuestions);
      // filter questionsData for questions that have the same ID as existingQuestions but have been edited
      const editedQuestions = questionsData.filter(ques => existingQuestions.some(origQues => origQues.question_id === ques.question_id && (origQues.question_text !== ques.question_text || origQues.question_type !== ques.question_type || origQues.raw_options !== ques.raw_options || origQues.sort_order !== ques.sort_order)));
      process.env.REACT_APP_ENV === 'staging' && console.log('editedQuestions:', editedQuestions);
      if (existingQuestions.length === 0 && newQuestions.length === 0 && editedQuestions.length === 0) {
        console.log('all empty');
        console.log('questionsData:', questionsData);
      } else if (existingQuestions.length === 0) {
        // there are no existing questions, so all questions in questionsData are new
        if (newQuestions.length > 0) {
          try {
            // create new questions
            const questionResponse = await api.post(`/questions/bulk`, {
              survey_id: survey_id,
              questions: newQuestions
            });
            if (questionResponse && questionResponse.status === 200) {
              setSaveSurveySnackbarOpen(true);
              // for every question in questionsData, update the corresponding question_id and survey_id
              // with the one returned from the server
              questionsData.forEach((ques, index) => {
                if (!ques.question_id) {
                  ques.question_id = questionResponse.data[index].question_id;
                  ques.survey_id = questionResponse.data[index].survey_id;
                }
              });
            }
          } catch (error) {
            console.error('Error making POST request', error);
            setErrorText(error.message || 'Error creating new survey questions');
          }
        }
      } else {
        // there are existing questions, so we need to update the existing ones and create the new ones
        if (newQuestions.length > 0) {
          try {
            // create new questions
            const questionResponse = await api.post(`/questions/bulk`, {
              survey_id: survey_id,
              questions: newQuestions
            });
            if (questionResponse && questionResponse.status === 200) {
              setSaveSurveySnackbarOpen(true);
              // for every question in questionsData, update the corresponding question_id and survey_id
              // with the one returned from the server
              questionsData.forEach((ques, index) => {
                if (!ques.question_id) {
                  // find the question in questionsData that matches the one in questionResponse
                  // based on the sort_order (b/c other fields can be duplicates) and
                  // set question_id and survey_id to the new ones returned from the server
                  for (let i = 0; i < questionResponse.data.length; i++) {
                    if (ques.sort_order === questionResponse.data[i].sort_order) {
                      ques.question_id = questionResponse.data[i].question_id;
                      ques.survey_id = questionResponse.data[i].survey_id;
                    }
                  }
                }
              });
            }
          } catch (error) {
            console.error('Error making POST request', error);
            setErrorText(error.message || 'Error creating new survey questions');
          }
        }
        if (editedQuestions.length > 0) {
          // remove the corresponding match from existingQuestions and questionsData before updating to prevent duplicates
          for (let i = 0; i < editedQuestions.length; i++) {
            setExistingQuestions(existingQuestions.filter(ques => ques.question_id !== editedQuestions[i].question_id));
          }
          for (let i = 0; i < editedQuestions.length; i++) {
            setQuestionsData(questionsData.filter(ques => ques.question_id !== editedQuestions[i].question_id));
          }
          // update edited questions
          for (let i = 0; i < editedQuestions.length; i++) {
            try {
              const questionResponse = await api.put(`/questions/${editedQuestions[i].question_id}`, {
                question_text: editedQuestions[i].question_text,
                question_type: editedQuestions[i].question_type,
                sort_order: editedQuestions[i].sort_order,
                raw_options: editedQuestions[i].raw_options,
                options: editedQuestions[i].raw_options ? editedQuestions[i].raw_options.split(',') : [""]
              });
              if (questionResponse && questionResponse.status === 200) {
                setSaveSurveySnackbarOpen(true);
              }
            } catch (error) {
              console.error('Error making POST request', error);
              setErrorText(error.message || 'Error editing survey questions');
            }
          }
        }
      }
      setSaveSurveySnackbarOpen(true);
      setQuestionsData(questionsData);
      setExistingQuestions(questionsData);
    }

    return true;
  }

  // this locks the "Options" column for editing in the Questions DynamicTable component for non-options question types,
  // because we want that col to be editable for single/multi choice questions only
  const enableOptionsEditing = (params) => {
    if (params.field === 'raw_options' && (params.row.question_type !== 'single choice' && params.row.question_type !== 'multiple choice')) {
      return false;
    } else {
      return true;
    }
  }

  const runSurvey = async () => {
    if (surveyId === null) {
      surveyId = surveyData.survey_id;
    }
    const confirmSurveyResponse = await api.get(`/surveys/${surveyId}/run`);

    if (confirmSurveyResponse) {
      const jobData = confirmSurveyResponse.data;
      surveyRunId = jobData.survey_run_id;
      surveyId = null;
      setPersistedSurveyId(null);
      audienceId = null;
    }

    navigate('/surveys');
  }

  const handleRemoveQuestion = (index) => {
    setQuestionsData(questionsData.filter((_, i) => i !== index));
  }

  const handleUpload = async (event) => {
    if (event.target.files.length === 0) {
      setErrorText('Please select a file to upload');
      return;
    } else {
      setErrorText('');
      if (persistedSurveyId === null) {
        // xlsx upload endpoint needs an ID, so we need to create a survey first
        const createResponse = await createSurvey(surveyName);
        surveyId = createResponse;
        setPersistedSurveyId(surveyId);
      } else {
        surveyId = persistedSurveyId
      }

      try {
        const response = await api.post(`/surveys/${surveyId}/upload-questions/xlsx`,
          {
            'xlsx_file': event.target.files[0],
          },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        if (response && response.status === 200) {
          // now that questions have been uploaded, pull them back from the api
          const getSurvey = await api.get(`/surveys/${surveyId}`);
          if (getSurvey && getSurvey.status === 200) {
            // questions in the DB only need options[] but in the ui we need raw_options for the table, so we create them before we set the questions data
            // and we also give each question an id, and we make sure the question type value is lowercase
            getSurvey.data.questions.forEach((question, index) => {
              question.options.length > 0 ? question.raw_options = question.options.join(',') : question.raw_options = '';
              question.id = index + 1;
              question.question_type = question.question_type.toLowerCase();
            });
            setQuestionsData(getSurvey.data.questions);
            setExistingQuestions(getSurvey.data.questions);
          }
        }
      } catch (error) {
        console.error('Error making POST request', error);
        setErrorText(error.message || 'Error uploading survey questions from XLSX');
      }
    }
  }

  const ErrorAlert = ({ errorText }) => {
    if (!errorText || errorText === '') {
      return;
    }

    return (
      <Alert
        severity="error"
        style={{ marginBottom: "20px", borderRadius: "20px" }}
        onClose={() => { setErrorText('') }}>
        <AlertTitle>Error</AlertTitle>
        {errorText}
      </Alert>
    );
  }

  const saveSurvey = async () => {

    if (process.env.REACT_APP_ENV === 'staging') {
      console.log('saving survey', persistedSurveyId);
      console.log('surveyName:', surveyName);
      console.log('audienceData', audienceData);
      console.log('existingSegments', existingSegments);
      console.log('segmentData', segmentData);
      console.log('questionsData', questionsData);
      console.log('surveyData', surveyData);
      console.log('isEditingSurvey', isEditingSurvey);
      console.log('isCreatingSurvey', isCreatingSurvey);
      console.log('isCopyingSurvey', isCopyingSurvey);
    }

    setIsSaving(true);
    if (!hasNameData || !hasAudienceData) {
        // audience check null or not
        !hasAudienceData ? setIsShowAudienceError(true) : setIsShowAudienceError(false);
        hasSurveyNameError || surveyName.trim() === '' ? setHasSurveyNameError(true) : setHasSurveyNameError(false);
      return;
    }
    // stitch together surveyData using the current state-based audienceData, segmentData, and questionsData
    let newSurveyData = {};

    newSurveyData = {
      ...surveyData,
      survey_id: persistedSurveyId,
      name: surveyName,
      audiences: [{
        ...audienceData[0],
        segments: segmentData
    }],
      questions: questionsData
    };

    process.env.REACT_APP_ENV === 'staging' && console.log('beginning save');

    // regardless of the current step, when the user clicks save, we want to determine whether
    // or not they're editing an existing survey. If so, we update that survey and its associated entities.
    // If not, then upon each save we create new entities and associate them with the new survey. Upon first save, we use
    // audience to create a survey (and survey_id)

    if (isEditingSurvey) {
      // editing existing survey
      if (activeStep === 0) {
      try {
          // attempt to save name
          const nameResponse = await api.put(`/surveys/${surveyData.survey_id}`, { name: surveyData.name });
          if (nameResponse && nameResponse.status === 200) {
            process.env.REACT_APP_ENV === 'staging' && console.log('name updated');
          }
        } catch (error) {
          console.error('Error making POST request', error);
          setErrorText(error.message || 'Error creating survey name');
        }
        // update audience
        try {
          const audienceResponse = await api.put(`/audiences/${audienceData[0].audience_id}`, { audience: audienceData[0].audience });
          if (audienceResponse && audienceResponse.status === 200) {
            process.env.REACT_APP_ENV === 'staging' && console.log('audience updated');
            setSaveSurveySnackbarOpen(true);
          }
        } catch (error) {
          console.error('Error making POST request', error);
          setErrorText(error.message || 'Error creating survey audience');
        }
        await saveSegments(audienceData[0].audience_id);
      } else {
        await saveQuestions(surveyData.survey_id);
      }

    } else if (isCreatingSurvey || isCopyingSurvey) {
      // creating / copying survey

      if (activeStep === 0) {
        // if null/'', that means it's a create / copy that hasn't saved its audience yet
        if (audienceData[0].audience_id === null || audienceData[0].audience_id === '') {
          const createSuccess = await createAudience();// this creates an audience and calls createSurvey
          if (createSuccess !== true) {
            setErrorText('Error creating audience');
            setActiveStep(0);
            return;
          } else {
            if (process.env.REACT_APP_ENV === 'staging') {
              console.log('audience created');
              console.log(persistedSurveyId);
              console.log('surveyData:', surveyData);
            }

            // as soon as we save successfully, we are now editing and no longer creating/copying
            setIsCopyingSurvey(false);
            setIsCreatingSurvey(false);
            setIsEditingSurvey(true);
            // at this point surveyData gets updated, and in useEffect we check to see if there are questions to save
            setSaveSurveySnackbarOpen(true);
          }
        } else {
          // there's a surveyId/persistedSurveyId, so we need to compare audience with existingAudience
          // to see if its been edited and if so, update it
          process.env.REACT_APP_ENV === 'staging' && console.log('audienceData:', audienceData);
          process.env.REACT_APP_ENV === 'staging' && console.log('existingAudiences:', existingAudiences);
          if (existingAudiences.length > 0) {
            // check if audience has been edited
            if (audienceData[0].audience !== existingAudiences[0].audience) {
              try {
                const audienceResponse = await api.put(`/audiences/${audienceData[0].audience_id}`, { audience: audienceData[0].audience });
                if (audienceResponse && audienceResponse.status === 200) {
                  process.env.REACT_APP_ENV === 'staging' && console.log('audience updated');
                  // as soon as we save successfully, we are now editing and no longer creating/copying
                  setIsCopyingSurvey(false);
                  setIsCreatingSurvey(false);
                  setIsEditingSurvey(true);
                  setSaveSurveySnackbarOpen(true);
                  if (surveyData.questions.length > 0) {
                    await saveQuestions(surveyData.survey_id);
                  }
                } else {
                  setErrorText('Error updating audience');
                  setActiveStep(0);
                  return;
                }
              } catch (error) {
                console.error('Error making POST request', error);
                setErrorText(error.message || 'Error updating audience');
                setActiveStep(0);
                return;
              }
            }
          }
        }
      } else if (activeStep === 1) {
        if (questionsData.length > 0) {
          await saveQuestions(surveyData.survey_id);
        }
      }
    }
  setIsSaving(false);
  }

  const loadExistingSurvey = async () => {
    if (process.env.REACT_APP_ENV === 'staging') {
      console.log('loading existing survey');
      console.log('isEditingSurvey:', isEditingSurvey);
      console.log('isCopyingSurvey:', isCopyingSurvey);
      console.log('existingSurveyId:', existingSurveyId);
      console.log('surveyId:', surveyId);
      console.log('persistedSurveyId:', persistedSurveyId);      
    }
    if (surveyId !== null || isCreatingSurvey) return;

    const response = await api.get(`/surveys/${existingSurveyId}`);

    if (response && response.status === 200) {
      setLoadedExistingSurvey(true);
      process.env.REACT_APP_ENV === 'staging' && console.log('found survey');
      let existingSurvey = response.data;
      process.env.REACT_APP_ENV === 'staging' && console.log('existingSurvey:', existingSurvey);
      process.env.REACT_APP_ENV === 'staging' && console.log('surveyData:', surveyData);

      if (isEditingSurvey) {
        process.env.REACT_APP_ENV === 'staging' && console.log('loading for edit...');
        // load the existing survey in its entirety
        surveyId = existingSurvey.survey_id;
        setPersistedSurveyId(surveyId);
        setSurveyName(existingSurvey.name);
        setAudienceData([{ ...existingSurvey.audiences[0] }]);
        // setSegmentData(existingSurvey.audiences[0].segments);
        // set id for each segment based on index
        setSegmentData(existingSurvey.audiences[0].segments.map((seg, index) => {
          return {
            ...seg,
            id: index + 1
          }
        }));
        setExistingSegments(existingSurvey.audiences[0].segments.map((seg, index) => {
          return {
            ...seg,
            id: index + 1
          }
        }));
        setExistingQuestions(existingSurvey.questions.map((ques, index) => {
          return {
            ...ques,
            id: index + 1,
            raw_options: ques.options.join(','),
          }
        }));
        setQuestionsData(existingSurvey.questions.map((ques, index) => {
          return {
            ...ques,
            id: index + 1,
            raw_options: ques.options.join(','),
            question_type: ques.question_type.toLowerCase()
          }
        }));
        setSurveyData(existingSurvey);
        process.env.REACT_APP_ENV === 'staging' && console.log('done loading for edit, surveyData:', surveyData);
      } else if (isCopyingSurvey) {
        // copying survey, so we want to create a new survey with empty IDs for all entities
        process.env.REACT_APP_ENV === 'staging' && console.log('loading for copy...');
        surveyId = '';
        setPersistedSurveyId('');
        setSurveyName(existingSurvey.name + ' (copy)');
        // clean up segments before adding to audienceData
        setSegmentData(existingSurvey.audiences[0].segments.map((seg, index) => {
          return {
            id: index + 1,
            audience_id: '',
            segment_name: seg.segment_name,
            segment_size: parseInt(seg.segment_size),
            audience_segment_id: '',
            has_name_error: false,
            has_size_error: false,
            sort_order: seg.sort_order
          }
        }));
        setAudienceData([{
          ...existingSurvey.audiences[0],
          created_at: '',
          audience_id: '',
          segments: segmentData
        }]);
        setExistingAudiences(audienceData);
        setExistingSegments(segmentData);
        setQuestionsData(existingSurvey.questions.map((ques, index) => {
          return {
            id: index + 1,
            survey_id: '',
            question_text: ques.question_text,
            question_type: ques.question_type.toLowerCase(),
            sort_order: ques.sort_order,
            raw_options: ques.options.join(','),
            options: ques.options,
            rankings: ['', '', '', '', ''],
            questions: ques.options.join(','),
            question_id: '',
            has_question_error: false
          }
        }));
        process.env.REACT_APP_ENV === 'staging' && console.log('done loading for copy, surveyData:', surveyData);
      } else {
        // shouldn't happen
        console.log('invalid survey context');
      }

      process.env.REACT_APP_ENV === 'staging' && console.log('load complete');
    } else {
      setErrorText('Error loading existing survey');
      console.log(response.data);
      surveyId = 0;
      setPersistedSurveyId(0);
    }
  }


  if (existingSurveyId !== null && loadedExistingSurvey === false) {
    loadExistingSurvey();
    return <Loading />;
  }

  return (
    <div className="dashboard">
        <div className="dashboard-content">
          <div>
          <Snackbar
            open={saveSurveySnackbarOpen}
            autoHideDuration={3000}
            onClose={(event, reason) => {
              setSaveSurveySnackbarOpen(false);
            }}
            message="Data successfully saved"
            className={'save-snackbar'}
            severity="success"
            variant="filled"
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          />
            <ErrorAlert errorText={errorText} />
            {
              activeStep === steps.length ? (
                <div className='left-panel' style={{ width: 'auto' }}>
                  <React.Fragment>
                    <div className='no-survey'>
                      <Loading />
                      <h2 sx={{ mt: 2, mb: 1 }}>
                        Please wait...
                        <span>simulating your survey</span>
                      </h2>
                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {/* <Button onClick={handleReset}>Reset</Button> */}
                    </Box>
                  </React.Fragment>
                </div>
              )
                :
                (
                  <React.Fragment>
                    <h1>{currentContext} Survey</h1>
                    {((window.location.pathname === "/" || window.location.pathname.includes('-survey')) && activeStep !== steps.length) && (
                        <Grid container className="stepper">
                          <Box>
                            <Stepper activeStep={activeStep}>
                              <Step key="Step 1">
                                <StepLabel>Audience & Segments</StepLabel>
                              </Step>
                              <Step key="Step 2">
                                <StepLabel>Questions</StepLabel>
                              </Step>
                              <Step key="Step 3">
                                <StepLabel>Review</StepLabel>
                              </Step>
                            </Stepper>
                          </Box>
                        </Grid>
                      )}
                    <div className='step' style={{ display: activeStep === 0 ? 'block' : 'none' }}>
                      <Audiences
                        activeStep={activeStep}
                        audienceData={audienceData}
                        setAudienceData={setAudienceData}
                        hasAudienceData={hasAudienceData}
                        hasSurveyNameError={hasSurveyNameError}
                        surveyName={surveyName}
                        isShowAudienceError={isShowAudienceError}
                        setIsShowAudienceError={setIsShowAudienceError}
                        validateSurveyName={validateSurveyName}
                        validateAudience={validateAudience}
                        segmentData={segmentData}
                        setSegmentData={setSegmentData}
                        hasSegmentData={hasSegmentData}
                        addSegment={addSegment}
                        addSegmentLimit={addSegmentLimit}
                        newSegmentName={newSegmentName}
                        hasSegmentNameError={hasSegmentNameError}
                        newSegmentSize={newSegmentSize}
                        hasSegmentSizeError={hasSegmentSizeError}
                        hasSegmentCountError={hasSegmentCountError}
                        setNewSegmentName={setNewSegmentName}
                        setNewSegmentSize={setNewSegmentSize}
                        validateSegment={validateSegment}
                        segmentActions={segmentActions}
                        currentContext={currentContext}
                      />
                    </div>
                    <div className="step" style={{ display: activeStep === 1 ? 'block' : 'none' }}>
                      <Questions
                        activeStep={activeStep}
                        questionsData={questionsData}
                        setQuestionsData={setQuestionsData}
                        hasQuestionsData={hasQuestionsData}
                        handleUpload={handleUpload}
                        newQuestionText={newQuestionText}
                        setNewQuestionText={setNewQuestionText}
                        hasQuestionTextError={hasQuestionTextError}
                        handleRemoveValidationForQuestionsChange={handleRemoveValidationForQuestionsChange}
                        newQuestionType={newQuestionType}
                        handleQuestionTypeFieldChange={handleQuestionTypeFieldChange}
                        newQuestionOptions={newQuestionOptions}
                        setNewQuestionOptions={setNewQuestionOptions}
                        hasQuestionOptionsError={hasQuestionOptionsError}
                        addQuestion={addQuestion}
                        addQuestionsLimit={addQuestionsLimit}
                        validateQuestion={validateQuestion}
                        enableOptionsEditing={enableOptionsEditing}
                        hasQuestionCountError={hasQuestionCountError}
                        audienceData={audienceData}
                        hasAudienceData={hasAudienceData}
                        segmentData={segmentData}
                        hasSegmentData={hasSegmentData}
                        selectSteps={selectSteps}
                        questionActions={questionActions}
                        currentContext={currentContext}
                      />
                    </div>

                    <div className="step" style={{ display: activeStep === 2 ? 'block' : 'none' }}>
                      <Step3Component
                        surveyName={surveyName}
                        audienceData={audienceData}
                        segmentData={segmentData}
                        questionsData={questionsData}
                        hasSegmentData={hasSegmentData}
                        selectSteps={selectSteps} />
                    </div>
                    <Box className="btnblock" sx={{ display: 'flex', alignSelf: "flex-end", flexDirection: 'row', pt: 2, width: (activeStep === steps.length - 1) ? '95%' : '66.6%' }}>
                      <Box sx={{ flex: '2'}}></Box>
                      <Button variant="outlined"
                        data-test-id="survey-back-btn"
                        size={"large"}
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        startIcon={<ArrowBackIosRoundedIcon />}
                      >
                        Back
                      </Button>
                      {activeStep < 2 && (
                        <Button
                          data-test-id="survey-save-btn"
                          className="save-btn"
                          variant="outlined"
                          size={"large"}
                          color="success"
                          sx={{ mr: 1 }}
                          onClick={() => saveSurvey(activeStep)}>
                          Save
                        </Button>
                      )}
                      <Button
                        disabled={isSaving}
                        data-test-id="survey-save-run-btn"
                        variant="contained"
                        size={"large"}
                        onClick={() => handleCreateNext()}
                        endIcon={<ArrowForwardIosRoundedIcon />}>
                        {activeStep === steps.length - 1 ? 'Run Survey' : 'Save and Continue'}
                      </Button>
                    </Box>
                  </React.Fragment>
                )
            }
          </div>
        </div>
    </div>
  );
}

export default withAuthenticationRequired(SurveyManager, {
  onRedirecting: () => <Loading />,
});