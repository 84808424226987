
import Loading from './ui/Loading';
import BarChart from './BarChart';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import download from '../assets/download-icon.svg';
import { Button } from '@mui/material';
import { useApi } from '../AuthProvider';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown'
import ContentCopy from '@mui/icons-material/ContentCopy';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Grid from '@mui/material/Unstable_Grid2';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Link from '@mui/material/Link';

const Result = () => {
  const [surveyRunResults, setSurveyRunResults] = useState(null);
  const params = useParams();
  const api = useApi();
  const [copySnackbarOpen, setCopySnackbarOpenOpen] = useState(false);
  const [surveyStatus, setSurveyStatus] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      let surveyRunsResponse = await api.get(`/surveys/runs/${params.surveyRunId}`);
      let surveyStatusResponse = await api.get(`/surveys/runs/${params.surveyRunId}/status`); 

      // sanitize surveyRunResults to support backwards compatibility with old survey results
      surveyRunsResponse.data.questions.forEach(question => {
        question.question_type = question.question_type.toLowerCase();
      });
      setSurveyRunResults(surveyRunsResponse.data);
      setSurveyStatus(surveyStatusResponse.data.status);
    }
    if (surveyRunResults === null && api) {
      fetchData();
    }
  }, [api]);

  const downloadSummary = (surveyRunId, summary) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([summary], {
        type: 'text/markdown',
        encoding: 'UTF-8'
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${surveyRunId}.md`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  const downloadFile = async (surveyRunId) => {
    var downloadResponse = await api.get(`/surveys/runs/${surveyRunId}/download/xlsx`, { responseType: "blob" });
    if (downloadResponse) {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([downloadResponse.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${surveyRunResults.name}.xlsx`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }

  const QuestionResultDetails = ({ question, answers }) => {
    const [numShow, setNumShow] = useState(5);
    const [textShow, setTextShow] = useState('Show more');
    if (question.question_type === 'short response' || question.question_type === 'long response') {
      return (
        <div>
          {/* if length answers is less than 5 */}
          {
            (answers.length <= 5)
            &&
            answers.map((answer, index) => (
              <div key={index}>
                <p>{answer.answer}</p>
              </div>
            ))
          }
          {/* if length answers is greater than 5 */}
          {
            (answers.length > 5)
            &&
            <div>
              {answers.slice(0, numShow).map((answer, index) => (
                <div key={index}>
                  <p>{answer.answer}</p>
                </div>
              ))}
              <Button
                onClick={() => {
                  if (numShow === 5) {
                    setNumShow(answers.length);
                    setTextShow('Show less');
                  }
                  else {
                    setNumShow(5);
                    setTextShow('Show more');
                  }
                }}
              >{textShow}</Button>
            </div>
          }
        </div>
      );
    } else if (question.question_type === 'multiple choice' || question.question_type === 'single choice') {
      let answerCounts = {};

      answers.forEach(answer => {
        answer.answer_array.forEach(answer => {
          if (answerCounts[answer.trim()] === undefined) {
            answerCounts[answer.trim()] = 1;
          } else {
            answerCounts[answer.trim()] += 1;
          }
        });
      });

      // sort answerCounts to display in the original order of the options
      answerCounts = Object.keys(answerCounts).sort((a, b) => question.options.indexOf(a) - question.options.indexOf(b)).reduce((obj, key) => {
        obj[key] = answerCounts[key];
        return obj;
      }, {});

      // NOTE: this behavior could/should become configurable in the future.
      // currently, we do not correlate the responses to the provided answers,
      // we just chart the given responses. This means that if the question is
      // multiple choice, and options are ['a', 'b', 'c'], but the responses contain
      // a combo response such as 'a, b', we will chart 'a, b' as a separate response.
      const labels = Object.keys(answerCounts);

      const totalAnswerCounts = Object.values(answerCounts).reduce((a, b) => a + b, 0);

      const datasets = {
        label: 'Responses',
        data: labels.map(label => (answerCounts[label] / totalAnswerCounts) * 100 || 0),
      }

      return (
        <BarChart data={Object.entries(answerCounts)} datasets={datasets} labels={labels} />
      );
    } else if (question.question_type === 'numeric rating') {

      const answerCounts = {};

      answers.forEach(answer => {
        if (answerCounts[answer.answer] === undefined) {
          answerCounts[answer.answer] = 1;
        } else {
          answerCounts[answer.answer] += 1;
        }
      });

      // instead of hardcoding the labels, we want to make sure that the
      // scale starts at min = 1, and max = 5 unless there is a response
      // higher than 5. then we fill in the blank labels in between min-max
      // that had no response for that value
      const labels = Object.keys(answerCounts);
      labels.forEach((label, index) => {
        labels[index] = parseInt(label);
      });
      // always begin at 1
      labels.unshift(1);
      // if the max isn't 5, make it 5
      Math.max(...labels) < 5 && labels.push(5);
      // between 1 and max, fill in any missing values
      for (let i = 1; i < Math.max(...labels); i++) {
        if (!labels.includes(`${i}`)) {
          labels.push(`${i}`);
        }
      }

      const totalAnswerCounts = Object.values(answerCounts).reduce((a, b) => a + b, 0);

      const datasets = {
        label: 'Responses',
        data: labels.map(label => (answerCounts[label] / totalAnswerCounts) * 100 || 0),
      }

      return (
        <BarChart data={Object.entries(answerCounts)} datasets={datasets} labels={labels} />
      );
    }
  }

  const QuestionAudienceSegmentResults = ({ question, segment, answers }) => {
    // this goes through the answers every question, so it'll start going REALLY slow if there are a lot of answers
    const segmentAnswers = answers.filter(answer => answer.question_id == question.question_id && answer.audience_segment_id == segment.audience_segment_id);
    return (
      <div>
        <h3>{segment.segment_name}</h3>
        <QuestionResultDetails question={question} answers={segmentAnswers} />
      </div>
    );
  }

  const QuestionResult = ({ questionNo, question, segments, answers }) => {
    return (
      <div>
        <h4>{questionNo}. "{question.question_text}"</h4>
        {segments.map((segment, index) => (
          <QuestionAudienceSegmentResults key={index} question={question} segment={segment} answers={answers} />
        ))}
      </div>
    );
  }
  const SurveySummaryA = ({ summary }) => {
    // If the summary is a string (the old way), render it as markdown
    if (typeof summary === 'string') {
      return (
        <Markdown className={'summary-block'}>{summary}</Markdown>
      );
    // If the summary is an object (the new way), render it as HTML
    } else if (typeof summary === 'object') {
      // detect if the summary block contains any markdown syntax, and if so, hide the block
      let summaryBlocks = Object.values(summary);

      summaryBlocks.forEach((value) => {
        if (value.includes('##') || value.includes('**')) {
          // remove it from the list
          summaryBlocks = summaryBlocks.filter((item) => item !== value);
        }
      });
      return (
        <div id="synthesis-div">
          {summaryBlocks.map((value, index) => (
            <div
              key={index}
              className={'summary-block'}
              dangerouslySetInnerHTML={{ __html: value }}>
            </div>
          ))}
        </div>
    )
    // If the summary is neither a string nor an object, render it as is
    } else {
      return (
        {summary}
      );
    }
  }
  const SurveySummaryB = ({ summary }) => {
    // If the summary is a string (the old way), render it as markdown
    if (typeof summary === 'string') {
      return (
        <>
        <Markdown className={'summary-block'}>{summary}</Markdown>
        </>
      );
    // If the summary is an object (the new way), render it as HTML
    } else if (typeof summary === 'object') {
      // detect if the summary block contains any markdown syntax, and if so, hide the block
      let summaryBlocks = Object.values(summary);

      summaryBlocks.forEach((value) => {
        if (value.includes('##') || value.includes('**')) {
          // remove it from the list
          summaryBlocks = summaryBlocks.filter((item) => item !== value);
        }
      });
      return (
        <div id="synthesis-div">
          {summaryBlocks.map((value, index) => (
            <SimpleCard key={index}>
                <div className={'summary-block'} dangerouslySetInnerHTML={{ __html: value }}></div>
            </SimpleCard>
          ))}
        </div>
    )
    // If the summary is neither a string nor an object, render it as is
    } else {
      return (
        {summary}
      );
    }
  }

  const QuestionResults = ({ questions, segments, answers }) => {
    return (
      <>
        {questions.map((question, index) => (
          <QuestionResult key={index} questionNo={index + 1} question={question} segments={segments} answers={answers} />
        ))}
      </>
    );
  }

  const copySummary = () => {
    let summary = surveyRunResults.summary;

    if (typeof summary === 'object') {
      // combine all the summary sections into one string
      summary = Object.values(summary).join('\n\n');
      const regex = /(<([^>]+)>)/gi;
      summary = summary.replace(regex, "");
    } else if (typeof summary === 'string') {
      // strip markdown
      summary = summary.replace(/(#)+ /g, '')// headers
        .replace(/\*/g, '')// bold
        .replace(/- /g, '\n');// lists
    }

    navigator.clipboard.writeText(summary);

    setCopySnackbarOpenOpen(true);
  }

  const SimpleCard = ({ title, content, children }) => {
    console.log('in card');
    console.log('title', title);
    console.log('content', content);
    console.log('children', children);
    let cardClass = 'simple-synthesis-block';
    const headerTags = ["<h1>", "<h2>", "<h3>", "<h4>"];
    const isExpanded = headerTags.some(el => content?.includes(el)) || headerTags.some(el => children?.props.dangerouslySetInnerHTML.__html.includes(el));
    if (isExpanded) {
      cardClass = 'expanded-synthesis-block';
    }

    return (
      <Card className={`simple-card ${cardClass}`} sx={12}>
        {/* <CardMedia
          sx={{ height: 140 }}
          image="/static/images/cards/contemplative-reptile.jpg"
          title="green iguana"
        /> */}
        <CardContent>
          <span className="card-title">{ title?.length > 0 && <h3>{ title }</h3> }</span>
          <span className="card-content">
            { cardClass === 'simple-synthesis-block' && <QueryStatsIcon color="primary" sx={{ position: 'relative', top: '7px', mr: 1, height: 32, width: 32, display: 'inline-block' }}/> }
            { content }
            { children }
          </span>
        </CardContent>
        {/* <CardActions>
          <Button size="small">Share</Button>
          <Button size="small">Learn More</Button>
        </CardActions> */}
      </Card>
    );
  }

  //////// Design A/B testing ////////
  const [designVersion, setDesignVersion] = React.useState('A');
  const [visibleSegments, setVisibleSegments] = React.useState(5);
  const [visibleQuestions, setVisibleQuestions] = React.useState(5);

  const DesignToggle = () => {
    const handleChange = (event, newVersion) => {
      setDesignVersion(newVersion);
    };
    return (
      <ToggleButtonGroup
        className={'design-toggle'}
        color="primary"
        value={designVersion}
        exclusive
        onChange={handleChange}
        aria-label="Design Toggle"
      >
        <ToggleButton value="A">Design A</ToggleButton>
        <ToggleButton value="B">Design B</ToggleButton>
      </ToggleButtonGroup>
    );
  }

  const DesignA = () => {
    return <Box className='left-panel results-block design-a' style={{ width: 'auto' }}>
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <h1>Survey Insights</h1>
        <h2>{surveyRunResults.name}</h2>
      </Grid>
      <Grid item xs={4} style={{ textAlign: 'right' }}>
        <a className='results-copy' onClick={() => copySummary()} style={{
          color: '#0D49A0',
          textDecoration: 'none',
          cursor: 'pointer'
        }}><ContentCopy style={{ height: "17px" }} /> Copy Summary</a>
      </Grid>
    </Grid>
    <SurveySummaryA summary={surveyRunResults.summary} />
    <div className='review-block'>
      <div className='result-header'>
        <h1>Survey Results</h1>
        <a onClick={() => downloadFile(params.surveyRunId)} className='download'><em><img src={download} alt="download" /></em>Download Results</a>
      </div>
      <div className='results-row'>
        <div className='results-col'>
          <ul>
            <li>
              Total responses
              <h2>{surveyRunResults.total_respondents || 0}</h2>
            </li>
            <li>
              Total questions
              <h2>{surveyRunResults.questions.length}</h2>
            </li>
            <li>
              Audience segments
              <h2>{surveyRunResults.audiences[0].segments.length}</h2>
            </li>
          </ul>
          <h2>Audience</h2>
          <h4>{surveyRunResults.audiences[0].audience}</h4>
        </div>
      </div>
      <div className='results-row'>
        <h2>Questions</h2>
        <QuestionResults questions={surveyRunResults.questions} segments={surveyRunResults.audiences[0].segments} answers={surveyRunResults.answers} />
      </div>
    </div>
  </Box>
  }

  const DesignB = () => {
    console.log('surveyRunResults', surveyRunResults);
    return <Box className='left-panel results-block design-b' style={{ width: 'auto' }}>
    <Grid container spacing={2}>
      <Grid className="report-controls" item xs={12}>
        <Grid className="report-status" item xs={4}>
          <span className={`${surveyStatus} status`}>{surveyStatus === 'running' ? 'In Progress' : surveyStatus}</span>
        </Grid>
        <Grid className="report-actions btnblock" item xs={8}>
          { surveyStatus === 'draft' &&
            <Button
              variant="contained"
              color="primary">
              Continue Editing
            </Button>
          }
          { surveyStatus === 'complete' &&
          <Button
              variant="contained"
              color="primary"
              onClick={() => downloadFile(params.surveyRunId)}>
              Download Results
            </Button>
            }
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {navigate(`/copy-survey/${surveyRunResults.survey_id}`)}}>
              Duplicate
          </Button>
          <Button variant="outlined" color="error">Delete</Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h1>{surveyRunResults.name}</h1>
      </Grid>
      <Grid item xs={12} className="report-stats">
        <Grid className="stats-group" item xs={8}>
          <Box className="stats-count">
            <Box className="stat">
              <p className="stat-value">{surveyRunResults.total_respondents || 0}</p>
              <p className="stat-label">Respondents</p>
            </Box>
          </Box>
          <Box className="stats-list">
            <p className="stats-list-title">Audience and Segments</p>
            <p className="stats-list-audience">{ surveyRunResults.audiences[0].audience }</p>
              {surveyRunResults.audiences[0].segments.map((segment, index) => (
                <Box sx={{ display: index > visibleSegments-1 ? 'none' : 'block'}}>
                  <p className="segment-label">Segment {index + 1} ({ segment.segment_size } Count)</p>
                  <Stack key={index} direction="row" spacing={1}>
                    <Chip className="chip segment-name" label={ segment.segment_name } />
                    <p className="segment-size"></p>
                  </Stack>
                </Box>
              ))
              }
              {surveyRunResults.audiences[0].segments.length > 5 &&
                <Link onClick={() => setVisibleSegments(visibleSegments === 5 ? surveyRunResults.audiences[0].segments.length : 5)}>{ visibleSegments === 5 ? 'Show More' : 'Show Less' }</Link>
              }
          </Box>
        </Grid>
        <Grid className="stats-group" item xs={8}>
          <Box className="stats-count">
            <Box className="stat">
              <p className="stat-value">{surveyRunResults.questions.length || 0}</p>
              <p className="stat-label">Questions</p>
            </Box>
          </Box>
          <Box className="stats-list">
            <p className="stats-list-title">Questions</p>
            {surveyRunResults.questions.map((question, index) => (
              <Box className="question-list-item" sx={{ display: index > visibleQuestions-1 ? 'none' : 'block'}} >
                <p className="question-list-type">({ question.question_type })</p>
                <p className="question-list-text">{ question.question_text }</p>
                  {question.options?.map((option, index) => (
                    <p className="question-list-option">{ question.options[index] }</p>
                  ))
                  }
              </Box>
            ))}
            {surveyRunResults.questions.length > 5 &&
              <Link onClick={() => setVisibleQuestions(visibleQuestions === 5 ? surveyRunResults.questions.length : 5)}>{ visibleQuestions === 5 ? 'Show More' : 'Show Less' }</Link>
            }
          </Box>
        </Grid>
      </Grid>
      {/* <Grid item xs={4} style={{ textAlign: 'right' }}>
        <a className='results-copy' onClick={() => copySummary()} style={{
          color: '#0D49A0',
          textDecoration: 'none',
          cursor: 'pointer'
        }}><ContentCopy style={{ height: "17px" }} /> Copy Summary</a>
      </Grid> */}
    </Grid>
    <SurveySummaryB summary={surveyRunResults.summary} />
    <div className='review-block'>
      <div className='result-header'>
        <h1>Survey Results</h1>
        <a onClick={() => downloadFile(params.surveyRunId)} className='download'><em><img src={download} alt="download" /></em>Download Results</a>
      </div>
      <div className='results-row'>
        <div className='results-col'>
          <ul>
            <li>
              Total responses
              <h2>{surveyRunResults.total_respondents || 0}</h2>
            </li>
            <li>
              Total questions
              <h2>{surveyRunResults.questions.length}</h2>
            </li>
            <li>
              Audience segments
              <h2>{surveyRunResults.audiences[0].segments.length}</h2>
            </li>
          </ul>
          <h2>Audience</h2>
          <h4>{surveyRunResults.audiences[0].audience}</h4>
        </div>
      </div>
      <div className='results-row'>
        <h2>Questions</h2>
        <QuestionResults questions={surveyRunResults.questions} segments={surveyRunResults.audiences[0].segments} answers={surveyRunResults.answers} />
      </div>
    </div>
  </Box>
  }
//////// end Design A/B testing ////////

  if (surveyRunResults === null) {
    return <Loading />;
  }
  process.env.REACT_APP_ENV === 'staging' && console.log('rendering results...');
  process.env.REACT_APP_ENV === 'staging' && console.dir(surveyRunResults);
  return (
    <div className="dashboard">
      <div className="dashboard-content">
      <Snackbar
        open={copySnackbarOpen}
        autoHideDuration={5000}
        onClose={(event, reason) => {
          setCopySnackbarOpenOpen(false);
        }}
        message="Copied to clipboard successfully."
        className={'copy-snackbar'}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
        {process.env.REACT_APP_ENV === 'staging' && (
          <DesignToggle />
        )}
        { designVersion === 'A' && (
          <DesignA />
        )}
        { designVersion === 'B' && (
          <DesignB />
        )}
      </div>
    </div>
  );
};

export default Result;
